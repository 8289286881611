import React, { PropsWithChildren, ReactElement, useContext, useMemo } from 'react';
import { IEntityCustomer } from 'module/customers';
import { IEntityPartnerDetail } from 'module/partners';
import { useLicenseCustomer, useLicenseDistributionPartner, useLicensePartner } from 'module/licenses/hooks';
import {
	useLicenseOperationErrorResolver,
	useLicenseOperationPriceListCodes,
} from 'module/licenses/hooks/licenseOperation';
import { useCountrySupportedCurrenciesOptions } from 'js/entities/country/useCountrySupportedCurrenciesOptions';
import {
	IEntityLicense,
	IEntityLicenseOperation,
	ILicenseOperationForm,
	TLicenseOperationBillableParty,
} from 'module/licenses';
import type { TSelectOptions } from '@avast/react-ui-components';
import { getLicenseOperationBillableParty, normalizeLicenseOperations } from 'module/licenses/utils/licenseOperation';
import { IEntityDistributionPartner } from 'module/distributionPartners';
import { AsyncSelectPartyModal, ILicenseOperationPartyResolution } from 'module/licenses/components/selectParty';
import { TAsyncModalRef, useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import { TPartnerPriceLists, TPriceListCode } from 'js/priceList';
import { isLicenseSegment } from 'module/licenses/utils/common';
import { ProductSegmentEnum } from 'module/licenses/enums';
import { BillablePartyTypeEnum } from 'module/orders/enums';
import { useOrderContext } from 'js/contexts';
import { CustomerSourceEnum } from 'module/customers/enums';

export interface ILicenseOperationContext {
	license: IEntityLicense;
	isLoading?: boolean;
	billableParty: TLicenseOperationBillableParty;
	licenseHasBillableParties: boolean;
	licenseOperationLoadError?: string | null;
	hasPartner: boolean;
	partner: IEntityPartnerDetail | null;
	hasCustomer: boolean;
	customer: IEntityCustomer | null;
	customerCurrencyOptions: TSelectOptions;
	isCustomerLinkedWithPartner: boolean;
	hasDistributionPartner: boolean;
	distributionPartner: IEntityDistributionPartner | null;
	licenseProductGroupIsBusiness: boolean;
	licenseProductOperations: IEntityLicenseOperation[];
	areRequiredFieldsFulfilled: (values: ILicenseOperationForm) => boolean;
	isEndCustomerBillableParty: boolean;
	selectPartyModalRef: TAsyncModalRef<{}, ILicenseOperationPartyResolution>;

	// Price list code
	priceListCode: TPriceListCode | null;
	partnerPriceLists: TPartnerPriceLists | null;
}

type TLicenseOperationContextProviderProps = PropsWithChildren<{
	license: IEntityLicense;
}>;

/**
 * Create context
 * @type {React.Context<IOrderContext>}
 */
const LicenseOperationContext = React.createContext<ILicenseOperationContext>({} as ILicenseOperationContext);
LicenseOperationContext.displayName = 'LicenseOperationContext';

export const useLicenseOperationContext = () => useContext(LicenseOperationContext);

export const LicenseOperationContextProvider = (props: TLicenseOperationContextProviderProps): ReactElement => {
	const { children, license } = props;
	const errorResolver = useLicenseOperationErrorResolver();
	const orderContext = useOrderContext();
	const selectPartyModalRef = useAsyncModalRef<{}, ILicenseOperationPartyResolution>();

	// Get partner from license
	const licensePartner = useLicensePartner(license);
	const { partner, isLoading: isPartnerLoading, hasPartner } = licensePartner;
	const { customer, isLoading: isCustomerLoading, hasCustomer, customerSource } = useLicenseCustomer(license);
	const billableParty: ILicenseOperationContext['billableParty'] = getLicenseOperationBillableParty(
		partner,
		customer,
		orderContext.billableParty,
	);
	const {
		distributionPartner,
		isLoading: isDistributionPartnerLoading,
		hasDistributionPartner,
	} = useLicenseDistributionPartner(license);
	const {
		options: customerCurrencyOptions,
		query: { isInitialLoading: isCustomerCurrenciesLoading },
	} = useCountrySupportedCurrenciesOptions(billableParty?.countryCode, hasCustomer && !hasPartner);

	// Get license operation price lists
	const {
		licensePriceListCode,
		partnerPriceLists,
		isLoading: isPriceListLoading,
	} = useLicenseOperationPriceListCodes(license, licensePartner);

	// Licence operations list
	const { allowedEntitlementLicenseOperations } = license.computedValues;
	const licenseProductOperations = useMemo(
		() => normalizeLicenseOperations(allowedEntitlementLicenseOperations),
		[allowedEntitlementLicenseOperations],
	);

	const value: ILicenseOperationContext = {
		license,
		isLoading:
			isPartnerLoading ||
			isCustomerLoading ||
			isDistributionPartnerLoading ||
			isCustomerCurrenciesLoading ||
			isPriceListLoading,
		billableParty,
		licenseHasBillableParties: hasCustomer || hasPartner,
		hasPartner,
		partner,
		hasCustomer,
		customer,
		customerCurrencyOptions,
		isCustomerLinkedWithPartner: Boolean(customer) ? customerSource === CustomerSourceEnum.PARTNER : true,
		hasDistributionPartner,
		distributionPartner,
		licenseProductGroupIsBusiness: isLicenseSegment(license, ProductSegmentEnum.SMB),
		licenseProductOperations,
		selectPartyModalRef,
		areRequiredFieldsFulfilled(values) {
			const { licenseBillableParty, licenseOperation } = values;
			if (
				licenseBillableParty?.billablePartyType === BillablePartyTypeEnum.END_CUSTOMER &&
				!licenseBillableParty?.currencyCode
			) {
				return false;
			}

			return Boolean(licenseOperation);
		},
		isEndCustomerBillableParty: billableParty?.billablePartyType === BillablePartyTypeEnum.END_CUSTOMER,
		priceListCode: licensePriceListCode,
		partnerPriceLists,
	};
	value.licenseOperationLoadError = errorResolver(value);

	return (
		<LicenseOperationContext.Provider value={value}>
			{children}
			<AsyncSelectPartyModal forwardedRef={selectPartyModalRef} />
		</LicenseOperationContext.Provider>
	);
};
