import { Reducer } from 'react';
import { logError } from 'js/utils/app';
import { IEntityPartnerDetail } from 'module/partners';
import { TPartnerPriceLists } from 'js/priceList';
import { IEntityCustomer } from 'module/customers';
import { IEntityDistributionPartner } from 'module/distributionPartners';
import { ILicenseOperationContext } from 'module/licenses/context/LicenseOperationContext';
import { isDifferentLicenseAndOrderParty } from 'module/licenses/utils/licenseOperation';
import { IIsDifferentLicenseAndOrderParty } from 'module/licenses/index';
import { IOrderContext } from 'js/contexts';
import { SelectPartyEnum } from './enums';

export type ILicenseOperationPartyResolution = {
	partner?: IEntityPartnerDetail | null | undefined;
	partnerPriceLists?: TPartnerPriceLists | null;
	customer: IEntityCustomer | null | undefined;
	distributionPartner: IEntityDistributionPartner | null | undefined;
};

export type TSelectPartyReducerState = ILicenseOperationPartyResolution & {
	step: SelectPartyEnum;
	isDifferentParty: IIsDifferentLicenseAndOrderParty;
};

type TSelectPartyReducerAction =
	| { type: 'SET_PARTNER'; payload: Pick<TSelectPartyReducerState, 'partner' | 'partnerPriceLists'> }
	| { type: 'SET_DISTRIBUTION_PARTNER'; payload: TSelectPartyReducerState['distributionPartner'] }
	| { type: 'SET_CUSTOMER'; payload: TSelectPartyReducerState['customer'] }
	| { type: 'BACK' };

export const selectPartyReducer: Reducer<TSelectPartyReducerState, TSelectPartyReducerAction> = (state, action) => {
	switch (action.type) {
		case 'SET_PARTNER': {
			const nextState = {
				...state,
				...action.payload,
			};

			if (!nextState.partner) {
				nextState.distributionPartner = null;
			}

			if (nextState.isDifferentParty.distributionPartner) {
				nextState.step = SelectPartyEnum.DISTRIBUTION_PARTNER;
			} else if (nextState.isDifferentParty.customer) {
				nextState.step = SelectPartyEnum.CUSTOMER;
			} else {
				nextState.step = SelectPartyEnum.RESOLVED;
			}

			return nextState;
		}
		case 'SET_DISTRIBUTION_PARTNER': {
			const nextState = {
				...state,
				distributionPartner: action.payload,
			};

			if (nextState.isDifferentParty.customer) {
				nextState.step = SelectPartyEnum.CUSTOMER;
			} else {
				nextState.step = SelectPartyEnum.RESOLVED;
			}

			return nextState;
		}
		case 'SET_CUSTOMER': {
			return {
				...state,
				customer: action.payload,
				step: SelectPartyEnum.RESOLVED,
			};
		}
		case 'BACK': {
			switch (state.step) {
				case SelectPartyEnum.PARTNER: {
					return {
						...state,
						step: SelectPartyEnum.CANCEL,
					};
				}
				case SelectPartyEnum.DISTRIBUTION_PARTNER: {
					if (state.isDifferentParty.partner) {
						return {
							...state,
							step: SelectPartyEnum.PARTNER,
						};
					}

					return {
						...state,
						step: SelectPartyEnum.CANCEL,
					};
				}
				case SelectPartyEnum.CUSTOMER: {
					if (state.isDifferentParty.distributionPartner) {
						return {
							...state,
							step: SelectPartyEnum.DISTRIBUTION_PARTNER,
						};
					}

					if (state.isDifferentParty.partner) {
						return {
							...state,
							step: SelectPartyEnum.PARTNER,
						};
					}

					return {
						...state,
						step: SelectPartyEnum.CANCEL,
					};
				}
				default:
					return state;
			}
		}
		default:
			logError('Not supported action', action);
			return state;
	}
};

export const getSelectPartyReducerState = (props: {
	licenseOperationContext: ILicenseOperationContext;
	orderContext: IOrderContext;
}): TSelectPartyReducerState => {
	const { orderContext, licenseOperationContext } = props;

	const isDifferentParty = isDifferentLicenseAndOrderParty(licenseOperationContext, orderContext.orderState);
	const step: TSelectPartyReducerState['step'] = ((): SelectPartyEnum => {
		if (
			!isDifferentParty.isDifferent ||
			// If order has not billable party and the license customer is linked with partner we can skip this process
			(!orderContext.hasBillableParty && licenseOperationContext.isCustomerLinkedWithPartner)
		) {
			return SelectPartyEnum.RESOLVED;
		}
		if (isDifferentParty.partner && orderContext.hasBillableParty) {
			return SelectPartyEnum.PARTNER;
		}
		if (isDifferentParty.distributionPartner && orderContext.hasBillableParty) {
			return SelectPartyEnum.DISTRIBUTION_PARTNER;
		}
		return SelectPartyEnum.CUSTOMER;
	})();

	return {
		step,
		isDifferentParty,
		partner: licenseOperationContext.partner,
		partnerPriceLists: licenseOperationContext.partnerPriceLists,
		distributionPartner: licenseOperationContext.distributionPartner,
		customer: licenseOperationContext.customer,
	};
};
